/* 
// 12. industry
*/

.industry-box {
    img{
        height: 22rem;
        width: 100%;
    }
    .industry-social-item {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.4);
        transform: translateY(60px);
        transition: all 0.5s;
        .industry-social-icon {
            color: $white;
            transition: all 0.2s;
        }
    }
    &:hover {
        .industry-social-item {
            transform: translateY(0px);
        }
    }
}
@each $color, $value in $theme-colors {
    .industry-social-icon {
        &:hover {
            &.h-#{$color} {
                color: #{$value};
            }
        }
    }
}