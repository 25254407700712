/* 
// 09. Service
*/

// service
.service-box {
    .service-box-content {
        position: relative;
        z-index: 1;
    }
    .service-icon {
        background-color: $primary;
        border-radius: 30% 70% 70% 30% / 30% 50% 50% 30%;
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba($primary, 1);
        border-radius: 78% 22% 22% 78% / 50% 22% 22% 50%;
        transform: scale(0) rotate(-50deg);
        transition: all 0.5s;
    }
    &:hover, &.active {
        &:after {
            transform: scale(1) rotate(0deg);
        }
        // &:before {
        //     transform: scale(1) rotate(-25deg);
        // }
    }
}